import React from 'react'
import PropTypes from 'prop-types'

export default function Spinner({ color = 'white', ...props }) {
  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        className={`c-spinner stroke-current text-${color}`}
      />
    </svg>
  )
}

Spinner.propTypes = {
  color: PropTypes.string,
}
