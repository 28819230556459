import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import { useShowMenu } from 'js/hooks/useShowMenu'

type Props = {
  onSignup?: (event: React.SyntheticEvent) => void
  onForgotPassword?: (event: React.SyntheticEvent) => void
  showSignupLink?: boolean
}
export default function LoginFooter({
  onSignup,
  onForgotPassword,
  showSignupLink,
}: Props) {
  const showMenu = useShowMenu()
  return (
    <div className="c-login-popover__link-wrapper">
      {onForgotPassword && (
        <button
          data-uid="link_to_forgot_password"
          className="c-login-popover__link"
          onClick={onForgotPassword}
        >
          <FormattedMessage {...messages.forgot} />
        </button>
      )}
      {onSignup && showMenu && showSignupLink && (
        <button
          data-uid="link_to_create_account"
          className="c-login-popover__link"
          onClick={onSignup}
        >
          <FormattedMessage {...messages.noAccount} />
        </button>
      )}
    </div>
  )
}
