export const insertGoogleScript = () => {
  return new Promise<void>((resolve, reject) => {
    if (window.google) {
      return resolve()
    } else if (!document.getElementById('google-script')) {
      let script = document.createElement('script')
      script.setAttribute('id', 'google-script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.addEventListener('load', () => resolve())
      script.addEventListener('error', reject)
      document.getElementsByTagName('body')[0].appendChild(script)
    }
  })
}
