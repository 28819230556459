import { LanguageCodes } from 'js/locale/constants'
import { getSlugLanguage } from 'js/locale/localizationHelper'

function localizeLink(
  path: `/${string}/?${string}`,
  currentLanguage: LanguageCodes,
)
function localizeLink(path: `/${string}/`, currentLanguage: LanguageCodes)
function localizeLink(path: `/`, currentLanguage: LanguageCodes)
function localizeLink(path: string, currentLanguage: LanguageCodes = 'en') {
  if (currentLanguage !== 'en') {
    return `/${currentLanguage}${path}`
  } else {
    return `${path}`
  }
}

function unlocalizePathname(pathname: string) {
  const slugLanguage = getSlugLanguage(pathname)
  if (slugLanguage) {
    return pathname.slice(slugLanguage.length + 1)
  }

  return pathname
}

export { localizeLink, unlocalizePathname }
