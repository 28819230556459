import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import { requestPasswordReset } from '../../../auth'
import { trackRequestNewPassword } from '../../../analytics'

import Checkmark from 'media/checkmark.svg?svgr-webpack'
import Input from './Input'
import LSButton from 'js/components/LSButton'

type Props = {
  onGoBack: () => void
}

const PasswordResetScreen = ({ onGoBack }: Props) => {
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState('')

  const handleFormSubmit = (event) => {
    event.preventDefault()

    trackRequestNewPassword()
    requestPasswordReset(email)
      .then(() => {
        setEmailSent(true)
      })
      .catch(() => {})
  }

  return (
    <div>
      <button
        data-uid="back_button"
        className="c-login-popover__back  w-button-as-text"
        onClick={onGoBack}
      >
        Back
      </button>
      <form action="/" onSubmit={handleFormSubmit}>
        {!emailSent && (
          <div>
            <h2 className="c-login-popover__box-heading">
              <FormattedMessage {...messages.resetPassword} />
            </h2>
            <p className="c-login-popover__instructions">
              <FormattedMessage {...messages.enterEmail} />
            </p>
            <div className="c-login-popover__input-group">
              <Input
                dataUid="email_reset_input"
                setValue={setEmail}
                type="email"
                id="emailResetInput"
                value={email}
                autoFocus
              />
              <label
                className="c-login-popover__label"
                htmlFor="emailResetInput"
              >
                <FormattedMessage {...messages.email} />
              </label>
            </div>
            <LSButton
              variant="primary"
              link={false}
              data-uid="reset_password_button"
              type="submit"
              className="mt-16 w-full"
              upperCase={true}
            >
              <FormattedMessage {...messages.resetPassword} />
            </LSButton>
          </div>
        )}
        {emailSent && (
          <div className="c-login-popover__instructions  s-text">
            <Checkmark id="checkmark" className="c-login-popover__checkmark" />
            <h2 className="c-login-popover__box-heading">
              <FormattedMessage {...messages.emailSent} />
            </h2>
            <p>
              <FormattedMessage {...messages.weveSent} />
            </p>
            <details className="c-login-popover__details">
              <summary className="c-login-popover__details-heading">
                <FormattedMessage {...messages.didntReceiveTitle} />
              </summary>
              <FormattedMessage
                {...messages.didntReceiveContent}
                values={{
                  p: function Paragraph(...chunks) {
                    return <p className="s-text">{chunks}</p>
                  },
                }}
              />
            </details>
          </div>
        )}
      </form>
    </div>
  )
}

export default PasswordResetScreen
