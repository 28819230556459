import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'Login.title',
    defaultMessage: 'Log in',
  },
  loggingIn: {
    id: 'Login.loggingIn',
    defaultMessage: 'Logging in…',
  },
  email: {
    id: 'Login.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'Login.password',
    defaultMessage: 'Password',
  },
  incorrect: {
    id: 'Login.incorrect',
    defaultMessage: 'The email or password is unfortunately incorrect',
  },
  forgot: {
    id: 'Login.forgot',
    defaultMessage: 'I forgot my password',
  },
  noAccount: {
    id: 'Login.noAccount',
    defaultMessage: 'I don’t have an account yet',
  },
  resetPassword: {
    id: 'Login.resetPassword',
    defaultMessage: 'Reset password',
  },
  enterEmail: {
    id: 'Login.enterEmail',
    defaultMessage:
      'Enter the email you used when creating your Lifesum account',
  },
  emailSent: {
    id: 'Login.emailSent',
    defaultMessage: 'Email sent!',
  },
  weveSent: {
    id: 'Login.weveSent',
    defaultMessage:
      'We’ve now sent an email to <b>{email}</b> with a link where you can choose a new password for your account.',
  },
  didntReceiveTitle: {
    id: 'Login.didntReceive.title',
    defaultMessage: 'I didn’t receive an email',
  },
  didntReceiveContent: {
    id: 'Login.didntReceive.content',
    defaultMessage:
      '<p>In rare cases it might take a few minutes for the email to arrive. Please wait a while, and also check your spam folder.</p><p>As a security precaution, we don’t disclaim whether the email address you provide is in our records or not. If the email address you provided isn’t in our records, no email will be sent.</p>',
  },
  signInWithEmail: {
    id: 'Redeem.login.signInWithEmail',
    defaultMessage: 'Sign in with email',
  },
  continueWithFacebook: {
    id: 'Redeem.login.continueWithFacebook',
    defaultMessage: 'Continue with Facebook',
  },
  errorFacebookWentWrong: {
    id: 'errorFacebookWentWrong',
    defaultMessage:
      'Something went wrong when trying to connect to your Facebook account. Please try again!',
  },
  dontWorry: {
    id: 'Redeem.login.dontWorry',
    defaultMessage:
      'Don’t worry, we’ll never share anything without your permission.',
  },
  signInWithApple: {
    id: 'Login.signInWithApple',
    defaultMessage: 'Sign in with Apple',
  },
  signInWithGoogle: {
    id: 'Login.signInWithGoogle',
    defaultMessage: 'Login with Google',
  },
})
