import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import {
  loginWithFacebook,
  selectIsLoggedIn,
  loginWithApple,
  loginWithGoogle,
} from '../../store/userSlice'
import { localizeLink, insertFBScript, insertGoogleScript } from '../../utils'
import PasswordResetScreen from './components/PasswordResetScreen'
import messages from './messages'
import { selectCoupon } from '../../store/pricingSlice'
import { trackChooseLoginMethod, trackForgotPassword } from '../../analytics'
import EmailLoginScreen from './components/EmailLoginScreen'
import LoginFooter from './components/LoginFooter'
import Spinner from '../Spinner/Spinner'
import { captureException } from 'js/utils/captureException'
import LSButton from '../LSButton'
import Apple from './assets/apple.svg?svgr-webpack'
import Facebook from './assets/facebook.svg?svgr-webpack'
import Google from './assets/google.svg?svgr-webpack'
import { AppDispatch } from 'js/app'
import useLocale from 'js/hooks/useLocale'
import { debug } from 'js/utils/debug'
import { facebookLoginAppId } from 'js/config'
import { useLocation } from 'react-router-dom'
import { getItem, setItem } from 'js/storeManager'

const START = 'START'
const EMAIL_LOGIN_FORM = 'EMAIL_LOGIN_FORM'
const PASSWORD_RESET = 'PASSWORD_RESET'

type Props = {
  onSuccess?: () => void
  onClose?: () => void
  onSignupClick?: () => void
  signupLink?: boolean
  showBackButton?: boolean
  showCloseButton?: boolean
  showSignupLink?: boolean
}

export const facebookLoginStateItemKey = 'facebookLoginState'

function Login({
  signupLink = true,
  showSignupLink = true,
  showCloseButton = true,
  onSignupClick,
  onSuccess,
  onClose,
}: Props) {
  const dispatch = useDispatch() as AppDispatch
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const coupon = useSelector(selectCoupon)

  const [facebookLoginState, setFacebookLoginState] = useState('')
  const [screen, setScreen] = useState(START)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | {
    id: string
    message: string
  }>(null)
  const [isReady, setIsReady] = useState(false)
  const [fbScriptIsBlocked, setFbScriptIsBlocked] = useState(false)
  const [googleScriptIsBlocked, setGoogleScriptIsBlocked] = useState(false)
  const { language } = useLocale()
  const location = useLocation()
  const useJSFacebookLogin = !navigator.userAgent.match(
    /instagram|bytedance|snapchat/i,
  )

  useEffect(() => {
    let unloaded = false
    const hashParams = new URLSearchParams(location.hash.slice(1))
    const token = hashParams.get('access_token')
    const state = hashParams.get('state')
    debug(JSON.stringify({ isReady, state, isLoggedIn, token }, null, 2))
    if (isLoggedIn) {
      return
    }

    const facebookLogin = async (token) => {
      debug(
        `facebookLoginAsync: ${JSON.stringify({ token, isReady, unloaded })}`,
      )
      if (unloaded) {
        return
      }

      while (!isReady) {
        debug(
          `facebookLoginAsync: ${JSON.stringify({ token, isReady, unloaded })}`,
        )
        if (unloaded) {
          return
        }
        await new Promise((resolve) => setTimeout(resolve, 500))
      }

      // remove the facebok redirect login credentials from the url
      window.location.hash = ''
      handleFacebookLoginClick(token)
    }

    if (token && state === getItem(facebookLoginStateItemKey)) {
      debug('login conditions are ok')
      facebookLogin(token)
    } else {
      debug('login conditions are not ok')
      const state = `${facebookLoginStateItemKey}-${Math.random()
        .toString(20)
        .substring(2)}`
      setFacebookLoginState(state)
      setItem(facebookLoginStateItemKey, state)
    }
    return () => {
      unloaded = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isReady, isLoggedIn])

  useEffect(() => {
    const insertTimestamp = Date.now()
    Promise.all([
      insertGoogleScript().catch(() => {
        setGoogleScriptIsBlocked(true)
        if (navigator.userAgent.match(/snapchat/i)) {
          return
        }

        captureException(new Error('LoadScriptError: google'), {
          context: {
            tags: {
              // if this is close to 30 seconds it's network issues
              // (not blocked)
              loadingDuration: Date.now() - insertTimestamp,
            },
          },
        })
      }),
      insertFBScript().catch(() => {
        captureException(new Error('LoadScriptError: fb'), {
          context: {
            tags: {
              // if this is close to 30 seconds it's network issues
              // (not blocked)
              loadingDuration: Date.now() - insertTimestamp,
            },
          },
        })
        setFbScriptIsBlocked(true)
      }),
    ]).finally(() => {
      setIsReady(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    debug(
      `onSuccess/onClose useEffec callback: ${JSON.stringify({
        isLoggedIn,
        onSuccess,
        onClose,
      })}`,
    )
    if (isLoggedIn === true) {
      if (onSuccess) onSuccess()
      if (onClose) onClose()
    }
  }, [isLoggedIn, onClose, onSuccess])

  function handleFacebookLoginClick(facebookRedirectAccessToken?: string) {
    setLoading(true)
    trackChooseLoginMethod({ method: 'Facebook' })
    dispatch(
      loginWithFacebook(
        facebookRedirectAccessToken,
        coupon === 'low-cost-test-purchase',
      ),
    ).catch((err) => {
      if (coupon === 'low-cost-test-purchase') {
        debug(`loginWithFacebook error object: ${JSON.stringify(err, null, 2)}`)
        debug(`loginWithFacebook error message: ${err.message}`)
      }
      setErrorMessage(err)
      setLoading(false)
    })
  }

  function handleAppleLoginClick() {
    setErrorMessage(null)
    setLoading(true)
    trackChooseLoginMethod({ method: 'Apple' })
    dispatch(loginWithApple()).catch((err) => {
      setErrorMessage(err)
      setLoading(false)
    })
  }

  function handleGoogleLoginClick() {
    // setLoading(true)
    dispatch(loginWithGoogle())
      .catch((error) => {
        setErrorMessage(error)
      })
      .finally(() => setLoading(false))
  }

  function handlePasswordResetClick() {
    setErrorMessage(null)
    trackForgotPassword()
    setScreen(PASSWORD_RESET)
  }

  function handleSignupClick() {
    if (onClose) {
      onClose()
    }

    if (onSignupClick) {
      onSignupClick()
    } else {
      let link = localizeLink('/signup/', language)
      if (coupon) {
        link = localizeLink(`/signup/?code=${coupon}`, language)
      }
      document.location = link
    }
  }

  function handleEmailLoginClick() {
    setErrorMessage(null)
    trackChooseLoginMethod({ method: 'Email' })
    setScreen(EMAIL_LOGIN_FORM)
  }

  function handleBackClick() {
    setScreen(START)
  }

  if (!isReady) return null

  return (
    <div className="o-width-limiter" data-testid="login-popover">
      <div className="c-login-popover__box m-auto">
        {showCloseButton && (
          <button
            data-uid="cross_button"
            className="c-login-popover__close  w-button-as-text"
            onClick={onClose}
          >
            Close
          </button>
        )}

        {screen === START && (
          <div>
            <h2 className={`c-login-popover__box-heading`}>
              <FormattedMessage {...messages.title} />
            </h2>
            <div
              id="googleSignInButton"
              /* the hidden class is more specific (lower down in the css file 
              so hidden will win) */
              className="mb-20 flex hidden justify-center"
            >
              {/* this is used by loginWithGoogle.ts to insert an sign in with
                google third party button. The hidden class is removed when the
                the button is rendered  */}
            </div>
            <div className="relative">
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Spinner width="50" color="green" />
                </div>
              )}
              <div
                className={classNames('', {
                  'opacity-25': loading,
                })}
              >
                {errorMessage && (
                  <p className="c-login-popover__error">
                    <FormattedMessage {...errorMessage} />
                  </p>
                )}
                {!useJSFacebookLogin &&
                location.pathname.match(/account|upgrade/) ? (
                  <LoginButton
                    link={true}
                    variant="secondary"
                    data-uid="continue_with_facebook_button"
                    href={`https://www.facebook.com/v18.0/dialog/oauth?client_id=${facebookLoginAppId}&state=${facebookLoginState}&response_type=token&redirect_uri=${encodeURIComponent(
                      window.location.href,
                    )}`}
                    icon={
                      <Facebook
                        className="-translate-y-1/2 transform"
                        style={{ width: 18 }}
                      />
                    }
                  >
                    <FormattedMessage {...messages.continueWithFacebook} />
                  </LoginButton>
                ) : null}
                {useJSFacebookLogin && (
                  <LoginButton
                    link={false}
                    variant="secondary"
                    data-uid="continue_with_facebook_button"
                    onClick={() => handleFacebookLoginClick()}
                    disabled={fbScriptIsBlocked ? true : false}
                    icon={
                      <Facebook
                        className="-translate-y-1/2 transform"
                        style={{ width: 18 }}
                      />
                    }
                  >
                    <FormattedMessage {...messages.continueWithFacebook} />
                  </LoginButton>
                )}
                <LoginButton
                  link={false}
                  variant="secondary"
                  data-uid="sign_in_with_apple_button"
                  onClick={handleAppleLoginClick}
                  icon={
                    <Apple
                      className="-translate-y-1/2 transform"
                      style={{ width: 18 }}
                    />
                  }
                >
                  <FormattedMessage {...messages.signInWithApple} />
                </LoginButton>
                {useJSFacebookLogin && (
                  <LoginButton
                    link={false}
                    variant="secondary"
                    onClick={handleGoogleLoginClick}
                    disabled={googleScriptIsBlocked ? true : false}
                    icon={
                      <Google
                        className="-translate-y-1/2 transform"
                        style={{ width: 18 }}
                      />
                    }
                  >
                    <FormattedMessage {...messages.signInWithGoogle} />
                  </LoginButton>
                )}
                <LoginButton
                  link={false}
                  variant="secondary"
                  data-uid="sign_in_with_email_button"
                  onClick={handleEmailLoginClick}
                >
                  <FormattedMessage {...messages.signInWithEmail} />
                </LoginButton>
              </div>
            </div>
            <LoginFooter
              onSignup={handleSignupClick}
              showSignupLink={showSignupLink}
            />
          </div>
        )}

        {screen === EMAIL_LOGIN_FORM && (
          <EmailLoginScreen
            onPasswordResetClick={handlePasswordResetClick}
            onSignupClick={signupLink ? handleSignupClick : undefined}
            onBackClick={handleBackClick}
          />
        )}

        {screen === PASSWORD_RESET && (
          <PasswordResetScreen onGoBack={handleBackClick} />
        )}
      </div>
    </div>
  )
}

export default Login

type LoginButtonProps = {
  children: React.ReactNode
  link: boolean
  icon?: React.ReactNode
  onClick?: () => void
} & React.ComponentProps<typeof LSButton>

function LoginButton({
  onClick,
  icon,
  children,
  link,
  ...props
}: LoginButtonProps) {
  return (
    <LSButton
      onClick={onClick}
      className="group isolate mb-16 flex w-full items-center justify-center"
      {...props}
      link={link}
      variant="secondary"
      type="button"
    >
      {Boolean(icon) && (
        <div className="mr-12 h-0 transition group-hover:opacity-50">
          {icon}
        </div>
      )}
      {children}
    </LSButton>
  )
}
