import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import messages from '../messages'
import { useDispatch } from 'react-redux'
import { loginWithEmail } from '../../../store/userSlice'
import LoginFooter from './LoginFooter'
import { AppDispatch } from 'js/app'
import Input from './Input'
import LSButton from 'js/components/LSButton'

type Props = {
  onPasswordResetClick?: () => void
  onSignupClick?: (e: React.SyntheticEvent) => void
  onBackClick?: () => void
}

export default function EmailLoginScreen({
  onPasswordResetClick,
  onSignupClick,
  onBackClick,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<null | {}>(null)
  const dispatch = useDispatch() as AppDispatch

  function handleEmailLoginSubmit(e) {
    setLoading(true)
    setErrorMessage(false)
    e.preventDefault()

    dispatch(
      loginWithEmail({
        email,
        password,
      }),
    ).catch((err) => {
      setErrorMessage(err)
      setLoading(false)
    })
  }

  return (
    <div>
      <button
        data-uid="back_button"
        className="c-login-popover__back  w-button-as-text"
        onClick={onBackClick}
      >
        Back
      </button>
      <h2 className={`c-login-popover__box-heading`}>
        <FormattedMessage {...messages.title} />
      </h2>

      <form
        className="c-login-popover__form"
        action=""
        onSubmit={handleEmailLoginSubmit}
      >
        {errorMessage && (
          <p data-uid="error_message" className="c-login-popover__error">
            <FormattedMessage {...errorMessage} />
          </p>
        )}
        <div className="c-login-popover__input-group mb-24">
          <Input
            dataUid="email_input"
            value={email}
            setValue={setEmail}
            type="email"
            id="emailInput"
          />
          <label className="c-login-popover__label" htmlFor="emailInput">
            <FormattedMessage {...messages.email} />
          </label>
        </div>
        <div className="c-login-popover__input-group">
          <Input
            dataUid="password_input"
            value={password}
            setValue={setPassword}
            type="password"
            id="passwordInput"
          />
          <label className="c-login-popover__label" htmlFor="passwordInput">
            <FormattedMessage {...messages.password} />
          </label>
        </div>
        <LSButton
          variant="primary"
          link={false}
          data-uid="login_button"
          type="submit"
          disabled={loading}
          upperCase={true}
          className="mt-16 w-full"
        >
          <span>
            {loading ? (
              <FormattedMessage {...messages.loggingIn} />
            ) : (
              <FormattedMessage {...messages.title} />
            )}
          </span>
        </LSButton>
      </form>
      <LoginFooter
        onSignup={onSignupClick}
        onForgotPassword={onPasswordResetClick}
      />
    </div>
  )
}
