import React from 'react'
import cn from 'classnames'

type Props = {
  value: string
  setValue: (value: string) => void
  dataUid: string
  type: string
  id: string
  autoFocus?: boolean
}

export default function Input({
  value,
  setValue,
  dataUid,
  type,
  id,
  autoFocus,
}: Props) {
  return (
    <input
      data-uid={dataUid}
      className={cn(
        'border-0 border-b-1 border-LSBrand pb-6 pt-16 outline-none',
        {
          'c-login-popover__field--has-value': value !== '',
        },
      )}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder=""
      type={type}
      id={id}
      autoFocus={autoFocus}
    />
  )
}
