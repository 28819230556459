import { facebookLoginAppId } from 'js/config'

export const insertFBScript = () => {
  return new Promise((resolve, reject) => {
    if (window.FB) {
      resolve()
    }

    if (!document.getElementById('facebook-script')) {
      const fbAsyncInitScript = document.createElement('script')
      fbAsyncInitScript.setAttribute('id', 'facebook-script')
      fbAsyncInitScript.textContent = `
        window.fbAsyncInit = function() {
          FB.init({
            appId: ${facebookLoginAppId},
            cookie: true,
            xfbml: true,
            version: 'v18.0'
          });
        }`
      document.body.appendChild(fbAsyncInitScript)
    }

    const facebookScriptElementId = 'facebook-jssdk'
    let facebookScript = document.getElementById(facebookScriptElementId)

    if (facebookScript) {
      resolve()
      return
    }

    facebookScript = document.createElement('script')
    facebookScript.setAttribute('id', facebookScriptElementId)
    facebookScript.src = 'https://connect.facebook.net/en_US/sdk.js'
    const insertBeforeScriptTag = document.getElementsByTagName('script')[0]
    facebookScript.addEventListener('load', resolve)
    facebookScript.onerror = reject
    insertBeforeScriptTag.parentNode.insertBefore(
      facebookScript,
      insertBeforeScriptTag,
    )
  })
}
